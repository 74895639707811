import React from "react";
import { FaHandshake } from "react-icons/fa";
import {
  FiArrowRightCircle,
  FiBell,
  FiCreditCard,
  FiGrid,
  FiHelpCircle,
  FiHome,
  FiLayout,
  FiMessageCircle,
  FiSettings,
  FiShoppingCart,
  FiShuffle,
  FiStar,
  FiTruck,
} from "react-icons/fi";
import { theme } from "./Theme";

const iconColor = "white";

export const supplierSidebarItems = (
  supplierAccountType,
  productCount,
  privatePortal,
  history,
  path,
  logout
) => {
  return [
    {
      text: "Products",
      icon: <FiGrid size="20" color={iconColor} />,
      active: path === "/supplier/products",
      // displayCondition: productCount > 0,
      onClick: () => history.push("/supplier/products"),
      top: true,
    },

    {
      text: "Orders",
      icon: <FiCreditCard size="20" color={iconColor} />,
      active: path.includes("/supplier/order"),
      onClick: () => history.push("/supplier/orders"),
      top: true,
    },
    {
      text: "Inbox",
      icon: <FiMessageCircle size="20" color={iconColor} />,
      active: path === "/supplier/inbox",
      onClick: () => history.push("/supplier/inbox"),
      top: true,
    },
    {
      text: "Vendors",
      icon: <FiShoppingCart size="20" color={iconColor} />,
      active: path === "/supplier/vendors/private",
      onClick: () => history.push("/supplier/vendors/private"),
      top: true,
    },
    {
      text: "Reviews",
      icon: <FiStar size="20" color={iconColor} />,
      active: path === "/supplier/supplier-reviews",
      onClick: () => history.push("/supplier/supplier-reviews"),
      top: true,
    },
    // {
    //   text: "Refunds",
    //   icon: <FiArchive size="20" color={iconColor} />,
    //   active: path === "/supplier/supplier-refunds",
    //   onClick: () => history.push("/supplier/supplier-refunds"),
    //   top: true,
    // },
    {
      text: "Accounts",
      icon: <FiShuffle size="20" color={iconColor} />,
      displayCondition: supplierAccountType === "accounts",
      onClick: () => history.push(`/accounts`),
      top: true,
    },
    {
      text: "Support",
      icon: <FiHelpCircle size="20" color={iconColor} />,
      onClick: () =>
        window.open(
          "https://help.dropcommerce.com/en/collections/2535973-dropcommerce-for-suppliers"
        ),
      bottom: true,
    },
    // {
    //   text: "Logout",
    //   icon: <FiLogOut size="20" color={iconColor} />,
    //   onClick: logout,
    //   bottom: true,
    // },
    {
      text: "Settings",
      icon: <FiSettings size="20" color={iconColor} />,
      onClick: () => history.push("/supplier/settings"),
      bottom: true,
    },
  ].filter(
    (item) => item.displayCondition === undefined || item.displayCondition
  );
};

export const storeSidebarItems = (
  storeAccountType,
  history,
  privatePortal,
  path,
  pathName,
  hidePrebuilt,
  platformName,
  logout,
  appName,
  handleOpen
) => {
  let sideBarItems;
  if (appName === "PREBUILT") {
    sideBarItems = [
      {
        text: "Prebuilt Stores",
        icon: <FiGrid size="20" color={iconColor} />,
        active: pathName === "/prebuilt-stores",
        onClick: () => history.push("/prebuilt-stores"),
        top: true,
      },
      {
        text: "My Stores",
        icon: <FiHome size="20" color={iconColor} />,
        active: pathName === "/my-stores",
        onClick: () => history.push("/my-stores"),
        top: true,
      },
      {
        text: "Premium",
        icon: <FiStar size="20" color={iconColor} />,
        active: pathName === "/premium",
        onClick: () => history.push("/premium"),
        top: true,
      },
      {
        text: "Support",
        icon: <FiHelpCircle size="20" color={iconColor} />,
        onClick: () =>
          window.open(
            "https://help.dropcommerce.com/en/articles/4361377-prebuilt-stores-faq"
          ),
        bottom: true,
      },
      // {
      //   text: "Logout",
      //   icon: <FiLogOut size="20" color={iconColor} />,
      //   onClick: logout,
      //   bottom: true,
      // },
    ];
  } else {
    sideBarItems = [
      {
        text: "Products",
        icon: <FiGrid size="20" color={iconColor} />,
        active:
          pathName === "/products" ||
          (privatePortal && pathName === `/supplier-id/${privatePortal.id}`),
        onClick: () =>
          history.push(
            privatePortal && appName !== "GLOBAL"
              ? `/supplier-id/${privatePortal.id}`
              : "/products"
          ),
        top: true,
      },
      {
        text: "Suppliers",
        icon: <FiTruck size="20" color={iconColor} />,
        active: pathName === "/suppliers",
        onClick: () => history.push("/suppliers"),
        displayCondition: appName !== "GLOBAL" && appName !== "PORTAL",
        top: true,
      },
      {
        text: "Imports",
        icon: <FiArrowRightCircle size="20" color={iconColor} />,
        active: path.includes("/imported"),
        onClick: () => history.push("/imported/list"),
        top: true,
      },
      {
        text: "Orders",
        icon: <FiCreditCard size="20" color={iconColor} />,
        active: path === "/orders",
        onClick: () => history.push(`/orders`),
        top: true,
      },
      {
        text: "Prebuilt",
        icon: <FiLayout size="20" color={iconColor} />,
        active: path === "/prebuilt",
        displayCondition:
          appName !== "PORTAL" &&
          platformName !== "BIGCOMMERCE" &&
          platformName !== "WIX" &&
          !hidePrebuilt,
        onClick: () => history.push("/prebuilt"),
        top: true,
      },
      {
        text: "Inbox",
        icon: <FiMessageCircle size="20" color={iconColor} />,
        active: path === "/inbox",
        onClick: () => history.push("/inbox"),
        top: true,
        displayCondition: appName !== "GLOBAL",
      },
      {
        text: "Changes",
        icon: <FiBell size="20" color={iconColor} />,
        active: path.includes("/notifications"),
        onClick: () => history.push("/notifications"),
        top: true,
      },
      {
        text: "Accounts",
        icon: <FiShuffle size="20" color={iconColor} />,
        displayCondition: !privatePortal && storeAccountType === "accounts",
        onClick: () => history.push(`/accounts`),
        top: true,
      },
      {
        text: "Partners",
        icon: <FaHandshake size="20" color={iconColor} />,
        onClick: () => window.open("https://dropcommerce.com/partners/"),
        top: true,
      },
      {
        text: "Support",
        icon: <FiHelpCircle size="20" color={iconColor} />,
        onClick: handleOpen,
        bottom: true,
        badge: {
          text: "new",
          gradient: {
            startingColor: theme.colors.main,
            finishingColor: "#ffff00",
          },
        },
      },
      // {
      //   text: "Logout",
      //   icon: <FiLogOut size="20" color={iconColor} />,
      //   onClick: logout,
      //   bottom: true,
      // },
      {
        text: "Settings",
        icon: <FiSettings size="20" color={iconColor} />,
        onClick: () => history.push("/settings"),
        bottom: true,
      },
    ];
  }
  return sideBarItems.filter(
    (item) => item.displayCondition === undefined || item.displayCondition
  );
};
